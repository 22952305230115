var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box form-box has-background-white" }, [
    _c(
      "div",
      { staticClass: "enso-form" },
      [
        _c("enso-form", {
          ref: "form",
          attrs: { "disable-state": "" },
          on: { ready: _vm.init, submit: _vm.submit },
          scopedSlots: _vm._u([
            {
              key: "video_src",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "columns custom-section-wrapper is-multiline"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-6 section-description" },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                " " + _vm._s(_vm.i18n(props.field.label)) + " "
                              )
                            ])
                          ]),
                          _c(
                            "b-field",
                            [
                              _c(
                                "b-upload",
                                {
                                  attrs: {
                                    "drag-drop": "",
                                    expanded: "",
                                    accept: "video/*"
                                  },
                                  on: { input: _vm.setVideo },
                                  model: {
                                    value: _vm.new_video_src,
                                    callback: function($$v) {
                                      _vm.new_video_src = $$v
                                    },
                                    expression: "new_video_src"
                                  }
                                },
                                [
                                  _c("section", { staticClass: "section" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content has-text-centered"
                                      },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "upload",
                                                size: "is-large"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("p", [_vm._v("Click to upload")])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column is-6" },
                        [
                          _vm.video_src || _vm.new_video_src
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "remove_tag",
                                  attrs: {
                                    closable: "",
                                    "aria-close-label": "Remove"
                                  },
                                  on: { close: _vm.resetVideo }
                                },
                                [_vm._v(" Remove ")]
                              )
                            : _vm._e(),
                          _vm.new_video_src
                            ? _c("video", {
                                attrs: {
                                  src: _vm.$displayImage(_vm.new_video_src),
                                  width: "100%",
                                  controls: ""
                                }
                              })
                            : _vm._e(),
                          _vm.video_src && !_vm.new_video_src
                            ? _c("video", {
                                attrs: {
                                  src: _vm.video_src,
                                  width: "100%",
                                  controls: ""
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "audio_src",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "columns custom-section-wrapper is-multiline"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-6 section-description" },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                " " + _vm._s(_vm.i18n(props.field.label)) + " "
                              )
                            ])
                          ]),
                          _c(
                            "b-field",
                            [
                              _c(
                                "b-upload",
                                {
                                  attrs: {
                                    "drag-drop": "",
                                    expanded: "",
                                    accept: "video/*"
                                  },
                                  model: {
                                    value: _vm.new_audio_src,
                                    callback: function($$v) {
                                      _vm.new_audio_src = $$v
                                    },
                                    expression: "new_audio_src"
                                  }
                                },
                                [
                                  _c("section", { staticClass: "section" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content has-text-centered"
                                      },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "upload",
                                                size: "is-large"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("p", [_vm._v("Click to upload")])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column is-6" },
                        [
                          _vm.audio_src || _vm.new_audio_src
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "remove_tag",
                                  attrs: {
                                    closable: "",
                                    "aria-close-label": "Remove"
                                  },
                                  on: { close: _vm.resetAudio }
                                },
                                [_vm._v(" Remove ")]
                              )
                            : _vm._e(),
                          _vm.new_audio_src
                            ? _c("video", {
                                attrs: {
                                  src: _vm.$displayImage(_vm.new_audio_src),
                                  width: "100%",
                                  controls: ""
                                }
                              })
                            : _vm._e(),
                          _vm.audio_src && !_vm.new_video_src
                            ? _c("video", {
                                attrs: {
                                  src: _vm.audio_src,
                                  width: "100%",
                                  controls: ""
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "logo",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "columns custom-section-wrapper is-multiline"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column is-6 section-description" },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(
                                " " + _vm._s(_vm.i18n(props.field.label)) + " "
                              )
                            ])
                          ]),
                          _c(
                            "b-field",
                            [
                              _c(
                                "b-upload",
                                {
                                  attrs: {
                                    "drag-drop": "",
                                    expanded: "",
                                    accept: "image/*"
                                  },
                                  model: {
                                    value: _vm.new_logo,
                                    callback: function($$v) {
                                      _vm.new_logo = $$v
                                    },
                                    expression: "new_logo"
                                  }
                                },
                                [
                                  _c("section", { staticClass: "section" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content has-text-centered"
                                      },
                                      [
                                        _c(
                                          "p",
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "upload",
                                                size: "is-large"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("p", [_vm._v("Click to upload")])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "column is-6" },
                        [
                          _vm.logo || _vm.new_logo
                            ? _c(
                                "b-tag",
                                {
                                  staticClass: "remove_tag",
                                  attrs: {
                                    closable: "",
                                    "aria-close-label": "Remove"
                                  },
                                  on: { close: _vm.resetLogo }
                                },
                                [_vm._v(" Remove ")]
                              )
                            : _vm._e(),
                          _vm.new_logo
                            ? _c("img", {
                                attrs: { src: _vm.$displayImage(_vm.new_logo) }
                              })
                            : _vm._e(),
                          _vm.logo && !_vm.new_logo
                            ? _c("img", {
                                attrs: { src: "/storage/" + _vm.logo }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }